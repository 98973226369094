import { StateHolder } from "./stateHolder";


export const getDefaultState = () => ({
  scrollCurrent:'game'
})

export const { getState, setState, addEventListener, clean } = StateHolder(getDefaultState());

export const setScrollCurrent = (scrollCurrent:string) => {
  if(scrollCurrent && scrollCurrent !== getState().scrollCurrent){
    setState({ scrollCurrent: scrollCurrent || '' })
    if(typeof window !== 'undefined' && window.history){
      window.history.replaceState(null, null, '#'+scrollCurrent);
    }
  }
}

//addEventListener(({scrollCurrent}) => console.log(scrollCurrent))

export type GlobalState = ReturnType<typeof getDefaultState>
import { Link } from "./Link"
import { Link as GatsbyLink } from "gatsby"
import React from "react"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import { getMenuIcons } from "./icons"
import { transparentize } from "polished"
import { useGlobalState } from "../utils/useGlobalState"
import logoSmall from "../images/logo-small.png"

const Header = styled.header`
  display: flex;
  z-index: 2;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #18262d;
  box-shadow: 0 1em 1em rgba(0, 0, 0, 0.2);
  @media print {
    display: none;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
  @media (min-height: 500px) and (min-width: 768px) {
    position: sticky;
    top: 0;
  }
`

const HeaderNav = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  padding: 1em;
  flex-basis: 60%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const HeaderSocialIcons = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1em;
  flex-basis: 20%;
`

const headerLinkStyle = css`
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  letter-spacing: 0.04em;
  display: inline-block;
  padding: 0.2em;
  margin-right: 2.3em;
  text-decoration: none;
  border-bottom: 2px solid #69787d;
  font-size: 1.2em;
  color: #69787d;
  text-align: center;
  text-transform: uppercase;
  @media (max-width: 1000px) {
    margin-right: 1.5em;
    &:last-child {
      margin-right: 0;
    }
  }
  @media (max-width: 768px) {
    margin-right: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  &[data-active]{
    color: #e3f4f4;
    border-bottom-color: #e3f4f4;
  }
`
const SocialButton = styled.a`
  background: #1ccab2;
  width: 2em;
  height: 2em;
  padding: 0.5em;
  border-radius: 4px;
  margin-right: 0.2em;
  &:last-child {
    margin-right: 0;
  }
  transition: all 0.5s ease-in;
  &:hover {
    transition: all 0.2s ease-out;
    box-shadow: 0 2px 25px ${transparentize(0.6, `#1ccab2`)};
  }
`

const links = {
  "the game": "#game",
  "demos & features": "#features",
  media: "#media",
  community: "#community",
  blog:'/blog'
}

const HeaderLink = ({ name, to }: { name: string; to: string }) => {
  
  const { scrollCurrent } = useGlobalState()

  const getProps = ({ _href, isCurrent, location:{ pathname, hash } }) => {
    if(pathname == '/'){
      hash = '/#thegame'
    }
    console.log({hash})
    if(hash){
      return '#'+scrollCurrent == to ? { "data-active": true} : {} 
    }
    return isCurrent ? { "data-active": true } : {}
  }

  return (
    <Link getProps={getProps} css={[headerLinkStyle]} to={to}>
      {name}
    </Link>
  )
}

export default ({ title }: { title: string }) => (
  <Header>
    <GatsbyLink
      to="/"
      css={css`
        display: inline;
        flex-shrink: 2;
        min-width: 0;
        display:flex;
      `}
    >
      <img src={logoSmall} alt="Transmogrify" />
    </GatsbyLink>

    <HeaderNav>
      {Object.keys(links).map((name: string) => {
        const to = links[name]
        return <HeaderLink key={to} to={to} name={name} />
      })}
    </HeaderNav>
    <HeaderSocialIcons>
      {getMenuIcons("main").map(({ Component, name, url }) => (
        <SocialButton key={url} href={url} target={"_blank"} title={name}>
          <Component fill="#f8fbfc" />
        </SocialButton>
      ))}
    </HeaderSocialIcons>
  </Header>
)

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Global, css } from "@emotion/core"
import { ToastContainer } from "react-toastify"
import Header from "./Header"
import "react-toastify/dist/ReactToastify.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Global
        styles={css`
          @import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;600&family=Montserrat:wght@400;600;700&display=swap");
          html,
          body,
          #root {
            margin: 0;
            padding: 0;
            min-height: 100vh;
            font-family: "Noto Sans", sans-serif;
            color: #def0ee;
            color: #cefff1;
            background: #1a262d;
            scroll-behavior: smooth;
          }
          input {
            padding: 0.2em;
            margin: 0;
            height: 1.6em;
            font-size: inherit;
            border: none;
            display: inline-block;
            vertical-align: middle;
            font-family: "Noto Sans", sans-serif;
            box-sizing: border-box;
            max-width: 90%;
          }
          input[type="submit"] {
            min-width: 7em;
            font-weight: 600;
            font-family: "Montserrat", sans-serif;
          }
          a {
            color: white;
          }
          h1, h2, h3, h4{
            margin:0;
          }
          h1, h2, h3, h4{
            padding: 1em;
          }
          @media print {
            p a[href^="http://"]:after, a[href^="https://"]:after
            {
              content: " (" attr(href) ")";
            }
            p a {
              word-wrap: break-word;
            }
            p {
              page-break-inside: avoid;
            }
          }
          .modal {
            position: absolute;
            background-color: #fdfdfd;
            border-radius: 3px;
            border-top-right-radius: 1em;
          }
          .overlay {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.2);
            box-shadow: inset 0 0 3em rgba(0, 0, 0, 0.3);
            z-index: 10;
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;
          }
          .ReactModal__Overlay {
            opacity: 0;
            transition: opacity 300ms ease-in-out;
          }
          .ReactModal__Overlay--after-open {
            opacity: 1;
          }
          .ReactModal__Overlay--before-close {
            opacity: 0;
          }
          pre, blockquote{
            background: #222;
            padding: 1em;
            margin: 0;
          }
        `}
      />
      <Header title={data.site.siteMetadata.title} />
      <main
        css={css`
          background-image: linear-gradient(to bottom, #5d1e2f, #18272e 30em);
          @media print {
            color: #cefff1;
            -webkit-print-color-adjust: exact;
          }
        `}
      >
        {children}
      </main>
      <ToastContainer />
    </>
  )
}

export default Layout

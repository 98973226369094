import { useState, useEffect } from "react"
import { addEventListener, getState } from "./globalManager"

export const useGlobalState = () => {
  const [ state, setState ] = useState(getState())

  useEffect(() => {
    return addEventListener(setState)
  }, [])


  return state
}
export interface StateListener<T>{
  ( state:T, oldState?:T): void
}

export const StateHolder = <T>(state:T) => {

  const eventsListeners = new Set<StateListener<T>>()

  const addEventListener = (listener:StateListener<T>) => { 
    eventsListeners.add(listener);
    return () => { eventsListeners.delete(listener) }
  }
  
  const setState = (newState: Partial<T>) => {
    if(newState === state){ return }
    const oldState = state
    state = {...state, ...newState}
    eventsListeners.forEach( listener =>  listener(state,oldState))
  }

  const getState = () => state

  const clean = () => { eventsListeners.clear() }

  return { addEventListener, setState, clean, getState }
}